.flyptospinner {
    width:50px;
    height:50px;
}

.pillbutton {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 8px;
    left: 10px;
}

.hidden {
    display: none;
}

.flyptospinner div {
    perspective-origin: center;
}

.flyptospinner div img {
    width: 100%;
    position: absolute;
    opacity: 1;
    backface-visibility: hidden; /* Ensures the back of the flip isn't visible */
    transform-style: preserve-3d;
    animation-name: flip;
    animation-duration: 2.4s; /* Timing adjusted to cycle through all images */
    animation-iteration-count: infinite;
	animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

/* Individual animation settings */
.dollar img { animation-delay: -1.8s; }
.bitcoin img { animation-delay: -1.2s; }
.euro img { animation-delay: -.6s; }
.ethereum img { animation-delay: 0s; }

/* Keyframes for flipping vertically */
@keyframes flip {
    0% {
        transform: rotateX(-180deg);
    }
    100% {
        transform: rotateX(180deg);
    }
}

