.loading_overlay {
    background: radial-gradient(circle, #19191a 0%, #212124c2 100%);
    width: 100%;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    place-content: center;
    z-index: 100;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, height 0s 0.3s;
}

.loading_overlay.visible {
    height: 100%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, height 0s;
}

.loading_overlay div[class*=flyptospinner],
.loading_overlay img[class*=wallet88spinner] {
    transition: all 0.2s;
    position: relative;
    display: flex;
}

.loading_overlay.flypto {
    background:#f9f9f9;
}

.loading_overlay.wallet88 {
    background: radial-gradient(circle, #ffffff 0%, #ffffffe8 100%);
}

.loading_overlay img {
    width: 62.5px;
}

.loading_overlay:not[class*=visible] img[class*=wallet88spinner],
.loading_overlay:not[class*=visible] div[class*=flyptospinner] {
    width: 0;
    height: 0;
    transition: all 0.2s;
}

@media (max-width: 767px) {
    body[class*=auth] .loading_overlay {
        grid-area: 2 / 1 / 4 / 3;
    }
}
