.container {
    display: grid;
    grid-template-columns: auto 50px auto;
    grid-template-rows: auto 50px auto;
    grid-template-areas: ". . ."
                         ". content ."
                         ". . .";
    width: 100%;
    height: 100%;
}

.content {
    grid-area: content;
    width: 50px;
    height: 50px;
}